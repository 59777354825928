import React from 'react'
import PropTypes from 'prop-types'

import jay from '../assets/images/About/jay.jpg'
import { WebsitePortfolio } from './WebsitePortfolio/WebsitePortfolio';
import axios from 'axios';
import { Contact } from './Contact/Contact';
import { Success } from './Success/Success';

import Loader from 'react-loader-spinner'
import { sendForm } from '../services/services';

const originalState = {
  name: "",
  subject: "S&A Contact Form",
  message: "",
  email: "",
  alert: "",
  success: '',
  nameFilled: true,
  emailFilled: true,
  messageFilled: true,
}

class Main extends React.Component {
  state = originalState;

  handleChange = (field, value) => {
    this.setState({ [field]: value })
  }

  resetFields = () => {
    this.props.onCloseArticle();
    this.setState(originalState);
  }

  validateInputs = async () => {
    this.setState({ loading: true });
    let { name, message, email } = this.state;
    let nameIsValid = true,
      emailIsValid = true,
      messageIsValid = true;
    if (!name) {
      nameIsValid = false;
    }
    if (!email) {
      emailIsValid = false;
    }
    if (!message) {
      messageIsValid = false;
    }
    if (emailIsValid && nameIsValid && messageIsValid) {
      this.sendEmail();
    }
    else {
      this.setState({ loading: false, nameFilled: nameIsValid, emailFilled: emailIsValid, messageFilled: messageIsValid })
    }
  }

  sendEmail = () => {
    let { name, email, message } = this.state;
    let obj = {
      name,
      email,
      site: 'jaysargent.com',
      message
    };
    sendForm(obj).then(() => {
      this.setState({ loading: false, success: 'true' })
    }).catch(error => {
      this.setState({ loading: false, success: 'false' })
    })
  }

  switchComponent = (close) => {
    let { name, nameFilled, email, emailFilled, message, messageFilled, success } = this.state;
    if (success === 'true') {
      return <Success close={close} resetFields={this.resetFields} />
    }
    else {
      return (
        <Contact 
          name={name} 
          nameFilled={nameFilled} 
          message={message} 
          messageFilled={messageFilled} 
          email={email} 
          emailFilled={emailFilled} 
          validateInputs={this.validateInputs} 
          resetFields={this.resetFields} 
          handleChange={this.handleChange} 
          close={close} 
          error={success === 'false'}
        />
      )
    }
  }

  render() {

    let close = <div className="close" onClick={this.resetFields}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>
        <article id="web" className={`${this.props.article === 'web' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Web</h2>
          <WebsitePortfolio />
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={jay} alt="" /></span>
          <h3 className="major">Jay Sargent</h3>
          <p> For as long as I can remember, I’ve had a passion for computers and for building things (thank you, LEGO®). When I first discovered there was a way that I could use computers to build and create, I knew that was exactly what I wanted to do with my life.</p> 
          <p> I studied Computer Science at Brigham Young University and Web Development at DevMountain. I've built a successful career both in the tech industry and as a freelance developer. I’ve gained a reputation for being innovative, hard working, efficient and incredibly easy to work with (not to mention I can definitely hold my own in Ping Pong). </p>
          <p> When I’m not behind my computer screen, you can find me either outside, exploring the world with my wife, or visiting my family in Sweden. </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          {!this.state.loading ?
            this.switchComponent(close)
            :  <Loader
            type="ThreeDots"
            color="#B99774"
            height="100"
            width="100"
          />
                }
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main