import React from 'react'
import PropTypes from 'prop-types'
import logo from '../assets/logo/transparent_logo.png'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        
        <img className="sa-logo" src={logo} alt="logo" />
        <div className="content">
            <div className="inner">
                <h1> Full Stack Web Developer </h1>
                <p> Yesterday's home runs don't win today's games. -Babe Ruth </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('web')}}>Portfolio</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
                <li><a target="_blank" href="https://jaysargent.s3-us-west-2.amazonaws.com/jay_resume_2020.pdf">Resume</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
